/* eslint-disable complexity */
import {
	Badge,
	Card,
	DatePicker,
	Descriptions,
	Form,
	Radio,
	Select,
	Spin,
} from 'antd';
import classNames from 'classnames';
import dayjs, { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { groupBy, isNil, map } from 'lodash-es';
import { roomStatusEnumeration } from 'blog/type-file';
import { endOfMonth, startOfMonth } from '../../../utils/timeUtil';
import { TrpcBase } from '../../../utils/trpc';
import { useUserAgent } from '../../../utils/userAgent';
import {
	constantLease,
	constantMonthlyStatement,
} from '../edit/categorySelectOption';
import { useBuildingConfig } from '../useBuildingConfig';
import { CheckDetail } from './CheckDetail';
import { IfRoomNotConfigureInfo } from './IfRoomNotConfigureInfo';
import { MonthTimePicker } from './MonthTimePicker';
import { Operation } from './Operation';
import { getDiffNowTime } from './getDiffNowTime';
import { ifIncludeTheTime } from './ifIncludeTheTime';
import { modeEnum } from './modeEnum';
import styles from './overview.module.css';
import { useApartmentList } from './useApartmentList';
import { useOverviewState } from './useOverviewState';
import { useSelectRoom } from './useSelectRoom';
import { usedChartTrend } from './usedChartTrend';
import { bigConstruct } from '../util/bigConstruct';
import { MathRound2 } from '../util/MathRound2';
import { BlurComponent } from '../myInfo/BlurComponent';
import { useKeyDownKeyboardListener } from '../../note/pc/hooks/useKeyDownKeyboardListener';

extend(isBetween);

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function Overview() {
	const { changeTitle } = useBuildingConfig();
	changeTitle('总览');

	const { isMobile, isPC, formLayout } = useUserAgent();

	const { room, setRoom, roomNumber } = useSelectRoom();

	const [stateParams, setStateParams] = useOverviewState();
	const paramsMode = stateParams?.mode ?? modeEnum.roomStatus;
	const delAction = TrpcBase.apartment.del.useMutation();
	const [form] = Form.useForm();
	const formTime = Form.useWatch('time', form);
	const formMode = Form.useWatch('mode', form);
	const { list } = useApartmentList({
		startTime: startOfMonth(formTime).valueOf(),
		endTime: endOfMonth(formTime).valueOf(),
		chargeStatus: '0',
		orderBy: 'checkInTime',
	});

	const { data: howLong } = TrpcBase.apartment.howLongNotRent.useQuery();

	const { data: configure } = TrpcBase.apartment.getRooms.useQuery();

	const roomConfigure = (configure ?? []).filter((i) => i.enabled === 1);

	// 楼层分组
	const floorGroup = groupBy(roomConfigure, (i) => {
		return Array.from(
			i?.roomNumber?.toString()?.matchAll(/(\d*)(\d\d)/g),
			(m: string[]) => m[1] as string,
		).pop();
	});

	useKeyDownKeyboardListener((e: KeyboardEvent) => {
		if (isNil(room)) {
			return;
		}

		const c = roomConfigure.findIndex((i) => i.uuid === room.uuid);
		if (c === -1) {
			setRoom(roomConfigure?.at(0));
			return;
		}

		if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
			e.preventDefault();
			let gpIndex = 0;
			const groupPos = Object.entries(floorGroup);

			const groupFloorIndex = groupPos.findIndex((i) => {
				const rm = i[1]?.findIndex((j) => j.uuid === room.uuid);
				if (rm !== -1) {
					gpIndex = rm;
					return true;
				}

				return false;
			});

			const upRes =
				groupFloorIndex - 1 < 0 ? groupPos.length - 1 : groupFloorIndex - 1;
			const downRes =
				groupFloorIndex + 1 >= groupPos.length ? 0 : groupFloorIndex + 1;
			const nFloorKey = e.code === 'ArrowUp' ? upRes : downRes;

			const nextRowGroup = groupPos.at(nFloorKey)?.[1];
			const nRoom = nextRowGroup?.at(
				gpIndex >= nextRowGroup.length ? nextRowGroup.length - 1 : gpIndex,
			);
			if (isNil(nRoom)) {
				return;
			}

			setRoom(nRoom);
		}

		if (e.code === 'ArrowLeft' || e.code === 'ArrowRight') {
			e.preventDefault();
			setRoom(roomConfigure.at(e.code === 'ArrowRight' ? c + 1 : c - 1));
		}
	});

	const {
		list: history,
		isFetching: isFetchingHistory,
		refetch,
	} = useApartmentList(
		{
			roomNumber: roomNumber,
			orderBy: 'checkInTime',
			limit: isPC ? 12 : 6,
		},
		!isNil(roomNumber),
	);

	const stateParamsMode = [
		modeEnum.roomStatus,
		modeEnum.remarkMode,
		modeEnum.vacancyItem,
		modeEnum.howLongNotRentMode,
	].includes(paramsMode)
		? paramsMode
		: modeEnum.roomStatus;

	const statPanel = (
		<div className={styles.panel}>
			<Descriptions title="统计">
				<Descriptions.Item label={'物业数量'}>
					<BlurComponent>{roomConfigure.length}</BlurComponent>
				</Descriptions.Item>

				<Descriptions.Item label={'其他数量'}>
					<BlurComponent>
						{roomConfigure.filter((i) => i.roomType !== 'room').length}
					</BlurComponent>
				</Descriptions.Item>

				<Descriptions.Item label={'房间总数'}>
					<BlurComponent>
						{roomConfigure.filter((i) => i.roomType === 'room').length}
					</BlurComponent>
				</Descriptions.Item>

				<Descriptions.Item label={'已租赁房间'}>
					<BlurComponent>
						{
							roomConfigure.filter(
								(i) =>
									i.roomType === 'room' &&
									i.roomStatus === roomStatusEnumeration.busy,
							).length
						}
					</BlurComponent>
				</Descriptions.Item>

				<Descriptions.Item label={'租赁率'}>
					<BlurComponent>
						{`${MathRound2(
							bigConstruct(
								roomConfigure.filter(
									(i) =>
										i.roomType === 'room' &&
										i.roomStatus === roomStatusEnumeration.busy,
								).length /
									roomConfigure.filter((i) => i.roomType === 'room').length,
							).toNumber() * 100,
						)}%`}
					</BlurComponent>
				</Descriptions.Item>

				<Descriptions.Item label={'空置房间数量'}>
					<BlurComponent>
						{
							roomConfigure.filter(
								(i) =>
									i.roomType === 'room' &&
									i.roomStatus === roomStatusEnumeration.open,
							).length
						}
					</BlurComponent>
				</Descriptions.Item>

				<Descriptions.Item label={'空置房间'}>
					<BlurComponent>
						{roomConfigure
							.filter(
								(i) =>
									i.roomType === 'room' &&
									i.roomStatus === roomStatusEnumeration.open,
							)
							.map((i) => i.roomNumber)
							.join(', ')}{' '}
					</BlurComponent>
				</Descriptions.Item>
			</Descriptions>
		</div>
	);

	const rentItems = (
		<>
			<div className="text text-lg m-3">{room?.roomNumber} 近期房租单</div>

			<div
				className={classNames(
					styles['detail-container'],
					'grid grid-cols-2 xl:grid-cols-3 ',
				)}
			>
				{history?.map((i, index) => (
					<CheckDetail
						isFirstNode={index === 0}
						showDelAction={index === 0}
						isShowUsed={formMode === modeEnum.watchUsed}
						key={i.uuid}
						onDel={() => {
							refetch?.();
						}}
						i={i}
						pick={ifIncludeTheTime(i, formTime)}
					/>
				))}
			</div>
		</>
	);

	return (
		<div>
			<Card>
				<Form
					layout={formLayout}
					form={form}
					initialValues={{
						mode: stateParamsMode,
						time: dayjs(stateParams?.time),
					}}
					onValuesChange={(v, all) => {
						setStateParams((pre) => {
							return {
								...pre,
								...all,
								time: v?.time?.valueOf(),
							};
						});
					}}
					onFinish={(values) => {}}
				>
					<Form.Item label="模式" name="mode">
						<Radio.Group
							options={[
								{ label: '查看当前房间状态', value: modeEnum.roomStatus },
								{ label: '关注空置期', value: modeEnum.howLongNotRentMode },
								{
									label: '查看该月审核单是否空缺',
									value: modeEnum.vacancyItem,
								},
								{ label: '关注本月的备注', value: modeEnum.remarkMode },
								{ label: '租金显示', value: modeEnum.rentMode },
								{ label: '水电情况', value: modeEnum.watchUsed },
							]}
						/>
					</Form.Item>

					<MonthTimePicker />
				</Form>

				<>
					{Operation(room)}
					{/* {roomConfigure?.length === 0 && configure === undefined && <NetworkError />} */}
					{roomConfigure?.length === 0 && configure !== undefined ? (
						<IfRoomNotConfigureInfo />
					) : (
						<>
							<div className={styles.panel}>
								<div className="flex flex-col ">
									<div
										className={classNames(
											// 'gap-1',
											styles['pretty-bar'],
											// 'overflow-y-auto',
											// // 'border-y-2 border-solid border-slate-300',
											// {
											//   'h-32': isMobile,
											//   'h-72': isPC,
											// },
										)}
									>
										{formMode === modeEnum.vacancyItem && (
											<div className="flex flex-row gap-2 m-3">
												<div>绿色：空置;</div>
												<div>粉色：空缺;</div>
												<div>红色：同一个月多个单;</div>
											</div>
										)}
										{map(floorGroup, (value, key) => {
											return (
												<div
													className={classNames(
														styles.row,
														//		'gap-1'
													)}
													key={key}
												>
													{value.map((i) => {
														const atPeriod = list.filter(
															(apply) => apply.roomNumber === i.roomNumber,
														);

														const haveRemark = atPeriod.some(
															(i) => i?.remark && i?.remark.length > 0,
														);

														const lastTime = howLong?.rows?.find(
															(j) =>
																j.roomNumber === i.roomNumber &&
																j.category === constantLease,
														)?.checkInTime;

														return (
															<div
																key={i.roomNumber}
																className="flex flex-col "
															>
																<div
																	onClick={() => {
																		setRoom((pre) => {
																			return pre === i ? null : i;
																		});
																	}}
																	onKeyDown={() => {
																		setRoom((pre) => {
																			return pre === i ? null : i;
																		});
																	}}
																	className={classNames(
																		'gap-2',
																		'hover:bg-sky-200',
																		`${styles.room} ${
																			room?.roomNumber === i?.roomNumber
																				? styles.selected
																				: ''
																		} ${
																			// 这里表示房间是否空置
																			// 将用房间本身的状态而不是通过备注去推测了。
																			formMode === modeEnum.roomStatus ||
																			formMode === modeEnum.rentMode ||
																			formMode === modeEnum.remarkMode ||
																			formMode === modeEnum.howLongNotRentMode
																				? i.roomStatus ===
																					roomStatusEnumeration.open
																					? styles['room-open']
																					: styles['room-busy']
																				: styles['room-normal']
																		} `,
																		{
																			// 如果房子本身有人住才需要去查看是否需要房租单
																			// 如果该月房租单一个都没有的话
																			[styles[
																				'not-right'
																			] as unknown as string]:
																				formMode === modeEnum.vacancyItem &&
																				i.roomStatus ===
																					roomStatusEnumeration.busy &&
																				atPeriod.length === 0,

																			// 查看模式 把空房间也显示成绿色
																			[styles[
																				'room-open'
																			] as unknown as string]:
																				formMode === modeEnum.vacancyItem &&
																				i.roomStatus ===
																					roomStatusEnumeration.open,
																		},
																		{
																			'bg-red-700':
																				list.filter(
																					(j) => j.roomNumber === i.roomNumber,
																				).length > 1,
																		},
																	)}
																>
																	<Badge
																		dot={
																			// 这里表示是否有备注的情况
																			formMode === modeEnum.remarkMode &&
																			haveRemark
																		}
																	>
																		<div className={styles.roomNumber}>
																			<div>{i.roomNumber}</div>
																		</div>
																	</Badge>
																</div>

																{modeEnum.howLongNotRentMode === formMode && (
																	<div className="text-red-500 text-center">
																		{getDiffNowTime(lastTime) !== 0 &&
																			`${getDiffNowTime(lastTime)}天`}
																	</div>
																)}

																{modeEnum.rentMode === formMode && (
																	<div className=" text-center">
																		{list
																			.find(
																				(j) =>
																					j.roomNumber === i.roomNumber &&
																					j.category ===
																						constantMonthlyStatement,
																			)
																			?.rent?.toString()}
																	</div>
																)}
															</div>
														);
													})}
												</div>
											);
										})}

										{modeEnum.rentMode === formMode && (
											<div className="bg-red-100 break-all p-2">
												<div>房租 - 房间号</div>
												{Object.entries(
													groupBy(
														list?.filter(
															(j) => j.category === constantMonthlyStatement,
														) ?? [],
														(i) => i.rent,
													),
												).map((entity) => {
													const [k, v] = entity;
													return (
														<div className="" key={k}>
															{k}: {v.map((aa) => aa.roomNumber).join(',')}
														</div>
													);
												})}
											</div>
										)}
									</div>

									{usedChartTrend(isPC, rentItems, history)}
								</div>
								{statPanel}
							</div>
						</>
					)}
				</>
			</Card>
		</div>
	);
}
